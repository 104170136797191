import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import Navbar from './Navbar';
import bilgilendirme from './pages/bilgilendirme';

function Footer() {
  return (
    <div class="footer">
    <div class="container">
      <div class="footer-top">
        <div class="row">
          <div class="col-md-6 col-lg-3 about-footer">
            <h3>İletişim Bilgileri </h3>
            <ul>
              <li><a href="tel:0(312) 324 17 00"><i class="fas fa-phone fa-flip-horizontal"></i> 0(312) 324 17 00</a></li>
              <li><a href="tel:0(507) 921 89 05"><i class="fas fa-phone fa-flip-horizontal"></i> 0(507) 921 89 05</a></li>
              
    
              <li><i class="fas fa-map-marker-alt"></i>
              Anıttepe, Turgut Reis Cd.
                <br />No:22/1, 06570,
                <br />Çankaya/Ankara
                
              </li>
            </ul>
            <Link to="/bilgilendirme" class="btn red-btn">İletişim</Link>
          </div>
          <div class="col-md-6 col-lg-2 page-more-info">
            <div class="footer-title">
              <h4>Menü</h4>
            </div>
            <ul>
              <li><Link to="/">Ana Sayfa</Link></li>
              <li><Link to="/products">Hakkımızda</Link></li>
              <li><Link  to="/services">Hizmetlerimiz</Link></li>
              <li><Link to="/bilgilendirme">İletişim</Link></li>
            
            </ul>
          </div>
  
          <div class="col-md-6 col-lg-3 page-more-info">
            <div class="footer-title">
              <h4>Yardımcı Linkler</h4>
            </div>
            <ul>
              <li><a href="https://www.turmob.org.tr/">TÜRMOB</a></li>
              <li><a href="https://www.resmigazete.gov.tr/">Resmi Gazete</a></li>
              <li><a href="https://portal.tnb.org.tr/Sayfalar/EnYakinNoter.aspx">En Yakın Noter</a></li>
              
            </ul>
          </div>
          <div class="col-md-6 col-lg-4 open-hours">
            <div class="footer-title">
              <h4>Çalışma Saatlerimiz</h4>
              <ul class="footer-social">
                <li><a href="" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                <li><a href="" target="_blank"><i class="fab fa-instagram"></i></a></li>
                <li><a href="" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
  
              </ul>
            </div>


            <div class="col-md-8">
                            <ul>
                                <li> <i class="far fa-clock"></i>Pazartesi :     08:00  - 17:00 </li>
                                
                                <li> <i class="far fa-clock"></i>Salı :     08:00  - 17:00 </li>
                                <li> <i class="far fa-clock"></i>Çarşamba :     08:00  - 17:00 </li>
                                <li> <i class="far fa-clock"></i>Perşembe :     08:00  - 17:00 </li>
                                <li> <i class="far fa-clock"></i>Cuma :     08:00  - 17:00 </li>
                                <li> <i class="far fa-clock"></i>Cumartesi :     08:00  - 17:00 </li>
                                <li> <i class="far fa-clock"></i>Pazar : Kapalı</li>
                            </ul>
                        </div>
            {/* <table class="table">
              <tbody>
                <tr>
                  <td><i class="far fa-clock"></i>Monday Thursday</td>
                  <td>9:00am - 5:00pm</td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i>Friday</td>
                  <td>9:00am - 4:00pm</td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i>Sturday</td>
                  <td>9:00am - 1:30pm</td>
                </tr>
                <tr>
                  <td><i class="far fa-clock"></i>Sunday</td>
                  <td>9:30am - 12:00pm</td>
                </tr>
              </tbody>
            </table> */}
            
            {/* <div class="footer-logo">
  
              <table>
                <tbody>
                  <tr>
                    <td>
                      <img src="https://i.ibb.co/vxc577d/dummy-logo3.jpg"
                      >
                        </img>
                      </td>
                    <td><img src="https://i.ibb.co/vxc577d/dummy-logo3.jpg"
                    >
                      </img>
                    </td>
                    <td><img src="https://i.ibb.co/vxc577d/dummy-logo3.jpg"
                    >
                      </img>
                    </td>
                    <td>
                      <img src="https://i.ibb.co/vxc577d/dummy-logo3.jpg"
                    >
                      </img>
                    </td>
                    <td><img src="https://i.ibb.co/vxc577d/dummy-logo3.jpg"
                    >
                      </img>
                    </td>
                    </tr>
                </tbody>
              </table>
            </div> */}
            
          </div>
        </div>
      </div>
     
      <div class="footer-bottom">
        <div class="row">
          <div class="col-sm-4">
            <a href="">Privacy policy</a>
          </div>
          <div class="col-sm-8">
            <p>ZAMBAK Mali Müşavirlik  @ 2021 All rights reserved</p>
          </div>
        </div>
      </div>
      
    </div>



    </div>

  );
}

export default Footer;
