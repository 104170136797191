import React from 'react';
import '../../App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import { Button } from '../Button';
import { Link } from 'react-router-dom';

import ReactDOM from "react-dom";
import Footer from '../Footer';




function bilgilendirme() {
  return (
    
   <div>
<div class="container">
  
    <div class="row" >
      <div class="col-6">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6369446.797337766!2d35.15192305746163!3d38.78476724583156!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14d34ee472154481%3A0x1a6b99cd96b42f8b!2zWmFtYmFrIE1hbGkgTcO8xZ9hdmlybGlrICYgQmHEn8SxbXPEsXogRGVuZXTDp2lsaWsgSGl6bWV0bGVyaQ!5e0!3m2!1str!2str!4v1613930392393!5m2!1str!2str"
          width="600"
          height="450"
          frameBorder="0"
          style={{ border: 0 }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          iframe />
      </div>
      <div class="col-6">
        <h4 class="heading-primary"> 
        İletişim Bilgileri 
        </h4>
      <div class="left w100y f14">
        <ul class="list list-icons list-icons-style-3 mt-xlg">
          <li class="mb15">
            <div>
               <p class="f16"> 
               <i class="fa fa-map-marker" aria-hidden="true">
                 </i> 
                 <strong>Adres:
                   </strong> 
                 </p>
                </div>
            <div>
              <p>
                
              Anıttepe, Turgut Reis Cd. No:22/1, 06570 Çankaya/Ankara
                
              </p>
            </div>
          </li>
          <li>
            <div>
               <p class="f16">
                  <i class="fa fa-phone">
                    </i>
                     <strong>Sabit Telefon:
                       </strong> 
                       </p> 
                       </div>
<div>
   <p> 
     <a href="tel:0(312) 324 17 00">
     0(312) 324 17 00
       </a> 
       </p> 
       </div>
</li>
<li>
            <div>
               <p class="f16">
                  <i class="fa fa-phone">
                    </i>
                     <strong>Cep Telefon:
                       </strong> 
                       </p> 
                       </div>
<div>
   <p> 
     <a href="tel:0(507) 921 89 05">
     0(507) 921 89 05
       </a> 
       </p> 
       </div>
</li>
<li>
<div> 
  <p class="f16"> 
  <i class="fa fa-fax">
    </i> <strong>Fax:
      </strong> 
      </p> 
      </div>
<div> 
  <p> 
    <a href="javascript:;">
      0(312) 324 17 00
      </a> 
      </p> 
      </div>
</li>
<li>
<div>
   <p class="f16">
      <i class="fa fa-mobile">
        </i>
         <strong> E-Posta:
           </strong>
            </p> 
            </div>
<div>
   <p>
     
   <a id="cpmIcerik_contact1_hlEposta" 
      href="mailto:zambakssmmm@hotmail.com" 
      target="_blank">
      zambaksmmm@hotmail.com
    </a>
        
         </p> 
         </div>
</li>
</ul>


</div>

</div>

</div>

</div>
<Footer/>
</div>


                      

    








                                



                            

                              





      
     

    );
}

export default bilgilendirme;
