import React from 'react';
import '../../App.css';
import pic from '../hizmetlerimiz.jpg';
import Footer from '../Footer';

 function Services() {
  return (
<div>
      <div class="container-md">
        <h4 class="heading-primary">Hizmetlerimiz</h4>
        <p></p>
        <p>
          <strong>
            <u>
            
                <img src={pic} 
                width="440px"
                 height="293px" 
                 float="right"
                  margin="5px"
                 className="ml-3"
                 
                alt="Generic placeholder" />
            </u>
          </strong>
        </p>
        <p>
        Yerli ve Yabancı şirketlerin, muhasebe kayıtlarının Türk mevzuatına ve Tek Düzen Hesap Planına göre tutulması, yapılan muhasebe kayıtları sonrası şirketlerin ihtiyacına yönelik, rapor, analiz ve yorumların yapılarak şirketlerin verimliliğine katkı sağlamak

        </p>
        <ul>
          <li>Alış, Satış,&nbsp;&nbsp;Gider faturaları, Tahsilat ve Tediye Makbuzları ile Kasa, Çek, Banka ve Cari Hesap hareketlerinin muhasebe sistemine girilmesi. Faaliyette bulunulan sektörün özelliğine göre muhasebe sisteminin kurulması</li>
          <li>Oluşturulan tüm muhasebe kayıtlarının vergi, sosyal güvenlik ve diğer kanunlarına uygunluğun kontrolü ve kontrol sonrası düzeltme işlemlerinin yapılması</li>
          <li>Muhasebe kayıtlarının işlenmesi ve kontrolü sonrası, mizanda bulunan tüm hesapların şekli ve içerik kontrollerinin yapılarak mutabakatların yapılması. Cari hesap, denetim notları, düzeltme öncesi ve sonrası durumu gösterir çalışma&nbsp;&nbsp;kağıtları ile yapılan mutabakatların belgelere dayandırılması.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
          <li>Muhasebe kayıtlarının işlenmesi ve kontrolü sonrası, mizanda bulunan tüm hesapların şekli ve içerik kontrollerinin yapılarak mutabakatların yapılması. Cari hesap, denetim notları, düzeltme öncesi ve sonrası durumu gösterir çalışma&nbsp;&nbsp;kağıtları ile yapılan mutabakatların belgelere dayandırılması.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
          <li>Firmanın ve Sektörün içinde bulunduğu, üretim gücü, piyasası, hammadde temini, finansal durumu ve benzeri kriterleri değerlendirerek; Firmanın Ekonomik dalgalanmalardan ne şekilde etkileneceğini tespit etmek amacıyla, Aylık Rapor standartları oluşturmak ve bu raporları analiz ederek sürecin en iyi şekilde yönetmesine destek olmak</li>
          <li>Aylık, Üç aylık, Altı aylık ve Yıllık olarak Gelir-Gider- Kar-Zarar&nbsp;&nbsp;Bütçesi, Nakit Akış Bütçesi, Yapmak. Fiili gerçekleşmeleri ay sonlarında Bütçeler ile kıyaslamak. Aylık Bilanço, Gelir Tablosu Hazırlamak, Gelir Tablosu ve Bilanço Kalemlerinin Dikey ve Yatay Finansal analizlerini yaparak Firmanın Banka ve Diğer Finans Kurumları nezdindeki kredibilitesini ölçmek.</li>
          <li>KDV, Muhtasar, Damga, Geçici, Gelir ve Kurumlar Vergisi Beyannamelerini Vergi Mevzuatı çerçevesinde hazırlamak, tahakkuk ettirmek ve ödemelerin yapılabilmesi için firmaya tahakkukları&nbsp;&nbsp;iletmek</li>
          <li>Vergi Daireleri ve Çalışma ve Sosyal Güvenlik Bakanlığına Bağlı,&nbsp;&nbsp;Sosyal Güvenlik Kurumlarındaki açılış, yazışma, düzletme, itiraz, şifre alma ve diğer işlemleri yapmak.</li>
          <li>Firmalardan, Çalışanların aylık Puantaj, Kazanç, Ek Kazanç, İndirim v.b. bilgilerini alarak, Aylık&nbsp;&nbsp;Ücret Bordrolarını hazırlamak. Bankaya Yatması gereken Maaş ve Asgari Geçim&nbsp;&nbsp;İndirimi&nbsp;&nbsp;(A.G.İ.) Tutarlarını bildirmek.</li>
          <li>SSK bildirgelerini hazırlanmak, tahakkuk ettirilmek ve ödemelerinin yapılabilmesi için ilgili departmana iletilmek</li>
        </ul>
        <p>
          <strong>
            <u>BEYANNAME HİZMETLERİ</u>
          </strong>
        </p>
        
        <ul>
        <li><strong><u>K.D.V.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</u></strong>&nbsp;1 ve 2 no’lu K.D.V. Beyannamelerinin düzenlenmesi, Tahakkuku&nbsp;ve bildirimi ile aylar&nbsp;&nbsp;ve&nbsp;yıllar itibariyle beyanname değerlerinin&nbsp;&nbsp;&nbsp;&nbsp;karşılaştırmalı analizinin yapılması.&nbsp;</li>
        <li><u><strong>MUHTASAR&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Muhtasar Vergi Beyannamelerinin düzenlenmesi, Tahakkuku ve&nbsp;Bildirimi ile&nbsp;Beyannameyi oluşturan kalemlerin detaylı&nbsp;&nbsp;&nbsp;Raporlanması.&nbsp;</li>
        <li><u><strong>GELİR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Ticari Kazanç (1. ve 2. sınıf Tüccarlar ve adi ortaklıklar)&nbsp;Menkul Sermaye İradı (Faiz, Temettü, alım satım kazancı)&nbsp;&nbsp;&nbsp;Gayrimenkul&nbsp;&nbsp;&nbsp;Sermaye&nbsp;&nbsp;(Kira Geliri)&nbsp;&nbsp;&nbsp;Diğer Gelirlere ilişkin Yıllık Gelir Vergisi Beyannamelerinin&nbsp;&nbsp;&nbsp;&nbsp;Hazırlanması, Tahakkuku ve bildirimi.&nbsp;</li>
        <li><u><strong>KURUMLAR&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Tam ve Dar Mükelleflere ait Yıllık kurumlar vergisi beyannamenin&nbsp;hazırlanması Tahakkuku ve bildirimi.&nbsp;</li>
        <li><u><strong>B.S.M.V.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Bankacılık ve Sigorta Muameleleri Vergisi&nbsp;&nbsp;Beyanı, Tahakkuku ve bildirimi&nbsp;</li>
        <li><u><strong>SSK&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Sosyal Güvenlik Kurumuna Aylık SSK Bildirimlerinin yapılması&nbsp;Hizmet Listeleri ile Tahakkuk fişlerinin bildirilmesi&nbsp;</li>
        <li><u><strong>BORDRO&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Çalışanlara ilişkin aylık bordroların hazırlanması. Aylık ve yıllık&nbsp;olarak işverene maliyetine ilişkin raporlama ve analizlerin&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;yapılması.&nbsp;</li>
        <li><u><strong>BAĞKUR&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:</strong></u>&nbsp;Bağ-Kur nezdinde açılış, kapanış, değişiklik, düzeltme, güncelleme&nbsp;işlemlerinin yapılması&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</li>
        </ul>
        <p>&nbsp;<strong><u>ŞİRKET&nbsp;&nbsp;KURULUŞ&nbsp;&nbsp;VE ÖRGÜTLENME HİZMETLERİ</u></strong></p>
        <ul>
        <li>Yabancı Şirket Kuruluşu (Anonim ve Limited)</li>
        <li>Yabancı Şirket Şube veya irtibat Bürosu Açılışı</li>
        <li>Serbest Bölgelerde Şirket Kuruluşu</li>
        <li>Anonim Şirket Kuruluşu</li>
        <li>Limited Şirket Kuruluşu</li>  
        <li>Adi Ortaklık&nbsp;&nbsp;&nbsp;Kuruluşu</li>
        <li>Şahıs Firması Kuruluşu</li>
        <li>Şirket Dönüşüm İşlemleri (Ltd.Şti.’den Anonim Şirkete veya Tersi Dönüşüm)</li>
        <li>Sermaye Artırımı ve Sermaye Ödendi rapor düzenleme</li>
        <li>Hisse Devri</li>
        <li>Adres Nakli</li>
        <li>Şube, Depo, Teşhir, Fabrika vb. Asıl işyerine bağlı ek birimlerin Açılışı</li>
        <li>Anonim Şirket Olağan ve Olağanüstü Genel Kurul İşlemleri</li>
        <li>Anonim Şirket Mali Genel Kurulu İşlemleri</li>
        <li>Şirket imza Yetkilisi Atanması</li>
        <li>Ana Sözleşmedeki maddelerinin Değişikliği</li>
        <li>Tasfiye Giriş işlemleri</li>
        <li>Tasfiye Sonu işlemleri</li>
        </ul>
        <p><strong><u>VERGİ DANIŞMANLIĞI</u></strong>&nbsp;</p>
        <p><strong><u>GAYRİMENKUL SERMAYE GELİRLERİNİN VERGİLENDİRİLMESİ</u></strong></p>
        <ul>
        <li>TL ve Döviz Cinsinden Konut Kira Gelirlerinin Vergilendirilmesi</li>
        <li>TL ve Döviz Cinsinden İşyeri Kira Gelirlerinin Vergilendirilmesi</li>
        <li>İşyeri Kira Gelirlerinden artan Stopaj alacağının iade işlemleri</li>
        <li>Yurtdışında Yaşayan T.C. Vatandaşlarının Elde ettiği Gayrimenkul Sermaye İratlarının Vergilendirilmesi</li>
        <li>Yabancı Ülke Vatandaşlarının Türkiye’de elde ettiği Kira Gelirlerinin Vergilendirilmesi</li>
        <li>Çifte Vergilendirmeyi önleme antlaşması çerçevesinde elde edilen Gayrimenkul Gelirlerinin Vergilendirilmesi</li>
        <li>Aile Bireyleri ile Küçüklerin, Kısıtlıların ve Mümeyyiz Olmayanların Vergilendirilmesi</li>
        <li>Gayrimenkul Sermaye Gelirlerinin istisnaları, muafiyetleri Konularında Danışmanlık</li>
        </ul>
        <p><strong><u>MENKUL SERMAYE GELİRLERİNİN VERGİLENDİRİLMESİ</u></strong></p>
        <ul>
        <li>TL ve Döviz Cinsinden Vadeli Mevduat Faiz Geliri Vergilendirilmesi</li>
        <li>Hazine Bonosu Faiz Geliri Vergilendirilmesi</li>
        <li>Eurobondlardan elde Edilen Gelirlerin Vergilendirilmesi</li>
        <li>Yurtdışında Yaşayan T.C. Vatandaşlarının Elde ettiği Menkul Sermaye İratlarının Vergilendirilmesi</li>
        <li>Kar Paylarının (Temettü Geliri) Vergilendirilmesi</li>
        <li>Repo Faiz Geliri Vergilendirilmesi</li>
        <li>Borsa Alım, Satım Kazancı Vergilendirilmesi</li>
        <li>Gayrimenkul Alım, Satım Değer Artış Kazancı Vergilendirilmesi</li>
        <li>Aile Bireyleri ile Küçüklerin, Kısıtlıların ve Mümeyyiz Olmayanların Vergilendirilmesi</li>
        <li>Menkul Sermaye İratlarının istisnaları, muafiyetleri vb. Konularda Danışmanlık</li>
        </ul>
        <p><strong><u>TİCARİ KAZANÇLARIN VERGİLENDİRİLMESİ (GELİR VERGİSİ)</u></strong></p>
        <ul>
        <li>Ticari Faaliyetlerin Özellikleri</li>
        <li>Şahıs Şirketlerinin Ortaklık Yapısı ve Vergilendirmede özellik arz eden hususlar</li>
        <li>Bilanço esasına tabi Birinci sınıf Tacirlerin Gerçek Usulde Vergilendirmesi</li>
        <li>İşletme Hesabı esasına tabi İkinci sınıf Tacirlerin Gerçek Usulde Vergilendirmesi</li>
        <li>Birinci Sınıftan İkinci Sınıfa Geçiş Koşulları</li>
        <li>İkinci Sınıftan Birinci Sınıfa Geçiş Koşulları</li>
        <li>Ticari Kazancın Tespitinde İndirilecek Giderler</li>
        <li>Ticari Kazancın Tespitinde İndirilemeyecek Giderler</li>
        <li>Diğer Kanunlara Göre İndirilebilecek ve indirilemeyecek Giderler</li>
        <li>Yıllara Sari İnşaat ve Onarma İşlerinin Vergilendirilmesi</li>
        <li>Ticari Kazancın Tespiti ve İstisnaları (PTT Acenteleri istisnası, Serbest Bölge istisnası, Eğitim Öğretim&nbsp;İşletmeleri istisnası, Sergi Panayır İstisnası,AR-GE istisnası, Gemilerin 2.sicile kayıtlı olması nedeniyle oluşan istisnalar, Yatırım İndirimi İstisnası, 5811 Sayılı Kanun Kapsamındaki İstisnalar</li>
        <li>Yurtdışı Faaliyetlerden Doğan Zararların Mahsubu</li>
        <li>Geçici Verginin Mükellefleri</li>
        <li>Geçici Vergi Dönemleri, Kapsamına Girmeyen Kazançlar,Geçici Vergiye Esas Kazancın Tespiti, Geçici Verginin Eksik Beyan edilmesi Durumu, Geçici Vergi Beyannamesine eklenecek Belgeler</li>
        <li>Yıllık Gelir Vergisi Beyannamesine Dahil Edilen Kazan ve İratlardan Yapılacak İndirimler (Sigorta Şirketlerine ve BES Ödenen Katkı Payları, Eğitim ve Sağlık Harcamaları, Bağış ve Yardımlar, Sınırlı İndirilecekler, Sınırsız İndirilecekler, Sponsorluk Harcamaları, Araştırma ve Geliştirme Harcamaları, Doğal Afetler ile ilgili Ayni ve Nakdi Bağışlar, İstanbul 2010 Avrupa Kültür Başkenti Ajansına Yapılan Bağış, Yardım ve Sponsorluk Harcamaları, Diğer Kanunlara göre Tamamı İndirilecek Bağış ve Yardımlar)</li>
        <li>Beyanname üzerinden Hesaplanan Vergiden Mahsup ve Kalan Kısmın iadesi</li>
        <li>Mahsuben İade</li>
        <li>Nakden İade</li>
        <li>Tevkif Yoluyla Kesilen Vergilerin Geçici Vergiye Mahsubu</li>
        <li>Geçici Verginin Mahsubu ve iadesi</li>
        <li>Yıllık Beyannamenin Verilme Yeri ve Zamanı</li>
        <li>Özellik Arz eden Durumlarda Gelir Vergisi Beyanının Verilmesi (Ölüm, Gaiplik, Kısıtlılık)</li>
        <li>Hesaplanan Verginin Ödenme Zamanı ve Yeri</li>
        <li>Beyannameye Eklenecek Bildirim ve Belgeler</li>
        <li>Beyanname Vermeyenlere Uygulanacak Yaptırımlar</li>
        </ul>
        <p><strong><u>KURUM KAZANÇLARININ VERGİLENDİRİLMESİ</u></strong></p>
        <ul>
        <li>Kurumlar Vergisinin Konusu ve&nbsp;&nbsp;Özellikleri</li>
        <li>Kurumlar Vergisi Tam ve Dar mükellefleri ve Özellikleri (Sermaye Şirketleri, Kooperatifler, İktisadi Kamu Kuruluşları, Dernek ve Vakıflara Ait İktisadi İşletmeler, İş Ortaklıkları (Joint Venture), Döner Sermayeli İşletmeler</li>
        <li>Kurumlar Vergisi Kanunu’nda Yer Alan Muafiyetler</li>
        <li>Diğer Kanunlarda Yer Alan Muafiyetler</li>
        <li>Kurumlar Vergisi Kanunu’na Göre İstisna Kapsamına Alınan Kazançlar</li>
        <li>Diğer Kanunlarda Yer Alan İstisnalar</li>
        <li>Uluslar arası Antlaşmalarla Sağlanan İstisnalar ve Uluslar arası Kurumlara Tanınan İstisnalar</li>
        <li>İstisna Uygulaması ve Önemli Konular</li>
        <li>Safi Kurum Kazancının Tespiti</li>
        <li>Safi Kurum Kazancının Tespitinde İndirilecek Giderler</li>
        <li>Safi Kurum Kazancının Tespitinde Zarar Mahsubu</li>
        <li>Safi Kurum Kazancının Tespitinde Diğer İndirimler</li>
        <li>Safi Kurum Kazancının Tespitinde Kanunen Kabul Edilmeyen Giderler</li>
        <li>Beyanname Verilme Zamanı, Yeri ve Eklenecek Belgeler</li>
        <li>Yıl İçinde Tevkif Yoluyla Yapılan Vergi Kesintilerinin durumu</li>
        <li>Vergi Alacaklarının İadesi ve Mahsubu</li>
        </ul>
        <p></p>
        <p></p>
        <p>&nbsp;&nbsp;<strong><u>OUTSOURCHING (DIŞ KAYNAK) TEMİNİ</u></strong>&nbsp;<strong><u>FİRMA&nbsp;&nbsp;İÇİ SÜRELİ – SÜRESİZ</u></strong></p>
        <p><strong><u>DIŞ KAYNAK TEMİNİ HİZMETLERİ</u></strong></p>
        <ul>
<li>Bordrolama</li>
<li>Cari Hesap Mutabakatları</li>
<li>Banka Kayıtlarının girilmesi</li>
<li>Ay içi evrak ve diğer hareket girişleri</li>
<li>İç Denetim</li>
<li>Raporlama</li>
<li>Analiz</li>
<li>Muhasebe Sistemi Kurulması</li>
<li>Finans Sistemi Kurulması</li>
<li>Verimlilik ve Karlılık Atışı için Maliyet Sistemi Kuruluşu</li>
</ul>
<p></p>



</div>

<Footer />
</div>
















    
    
    
       

    
    

     
  )
}

export default Services;
