import React from 'react';
import '../../App.css';
import pic from "../hizmetlerimiz.jpg";




import Footer from '../Footer';

 function Products() {
  return (
    
    
  <div class="left w100y mb15 icerik-kapsa" >
    <h4 class="heading-primary">Hakkımızda</h4>
    <p></p>
    <img src={pic} 
                width="440px"
                 height="293px" 
                 float="left"
                  margin-left="5px"
                  margin-right="5px"
                 className="ml-3"
                 
                alt="" />

   
      <p >ZAMBAK Mali Müşavirlik; Muhasebe sektöründeki tecrübesini ve bilgisini tüm sektörlere yayılması amacı ile uzun yıllar kurumsal firmalarda Genel Muhasebe Müdürlüğü yapmış olan Serbest Muhasebeci Mali Müşavir Fehmi Zambak tarafından 2001 yılında kurulmuştur.
          ZAMBAK Mali Müşavirlik ofisi alanında uzman 3 mali müşavir, 1 SSK danışmanı, 1 hukuk danışmanı  ve 2 stajer  kadrosu ile firmaların gereksinimlerine göre özel olarak geliştirilmiş çözümler sunmaktadır.
          ZAMBAK Mali Müşavirlik, muhasebe, vergi, denetim ve danışmanlık konularında hizmet veren bir müşavirlik ofisidir.
          Farklı sektörler ve farklı büyüklüklerdeki firmalara; muhasebe hizmetleri, SGK hizmetleri, denetim hizmetleri, KDV İade hizmetleri, vergi denetimi ve vergi danışmanlığı hizmetleri sağlamaktadır.
      </p>
      <p>ZAMBAK Mali Müşavirlik, uzman, deneyimli, meslek ahlakı, şeffaflık, gizlilik ve disiplin ilkelerimizden asla ödün vermeden dinamik kadromuzla doğru, güvenilir ve güncel bilgiye hızlı şekilde ulaşarak firmalara muhasebe ve vergi konularında çözüm odaklı hizmet sunmaktayız. Siz değerli mükelleflerimizin muhasebe alanında eksik veya yanlış bilgilendirilmiş olan mevzuatlarda kadromuzla birlikte mevzuat uygulamalarında yapılan değişiklikleri bilgilendirilmelerle yolunuzda ivme kazandırmak en önemli uygulamalarımız arasında yer almaktadır. Şirketlerin finansmanlarına artı değer kazandırmak adına en önemli krateri mizanlarının mevzuata uygun ve anlaşılır olmasıdır. ZAMBAK Mali Müşavirlik olarak firmalarımızın mizan çalışmalarında mükelleflerimizle çözüm odaklı ve sistematik şekilde bilgi akışlarının sağlanmasındaki hassasiyetimiz, mükelleflerimizin kredi kullanımlarında bankalar nezrinde oldukça önem kazandırmaktadır.

      </p>
      <p>
      ZAMBAK Mali Müşavirlik olarak siz değerli mükelleflerimizi ofisimizde ağırlamaktan duyacağımız memnuniyetin karşılığında, anlayış içerisinde isteklerinizi dinlemekteki hassasiyetimizi danışanlarımızla sizlere yansıtmaktaki profesyonelliğimizle yolumuza devam ederken mesleğimize duyduğumuz sadakatla hizmetlerimizi sunmaktaki mutluluğumuzu paylaşmaktayız.
      </p>
   

     
     
     <Footer/>
  </div>
  

  
 
  
  )
}
export default Products;
