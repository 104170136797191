import React from 'react';
import '../../App.css';
import Cards from '../Cards';
import HeroSection from '../HeroSection';
import Footer from '../Footer';
import bakın from '../bakın';


function Home() {
  return (
    <>
    
      <HeroSection />
      
      <Cards />

      <bakın/>


      <Footer />

      
    </>
  );
}

export default Home;