import React, { useState, useEffect, Image, useImperativeHandle} from 'react';
import { Button } from './Button';
import { Link } from 'react-router-dom';
import './Navbar.css';
import logo from './mali-logo.png';
import logo2 from './onlinelogomaker-121617-1941-3068.png';

function Navbar() {
  const [click, setClick] = useState(false);
  const [button, setButton] = useState(true);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const showButton = () => {
    if (window.innerWidth <= 960) {
      setButton(false);
    } else {
      setButton(true);
    }
  };

  useEffect(() => {
    showButton();
  }, []);

  window.addEventListener('resize', showButton);

  return (
    <>
      <nav className='navbar'>
        <div className='logo' onClick={closeMobileMenu}> 
        
        <Link to='/' 
        className='logo' onClick={closeMobileMenu}>
          <img src={logo} width="50" height="50"  />
        </Link>
        
        FEHMİ ZAMBAK <br></br>
        Serbest Muhasebeci Mali Müşavir
        
        
        </div>
         <div className='navbar-container'>
          {/* <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
            ZAMBAK MUHASEBE
            <i class='fab fa-typo3' />
           
          </Link>  */}
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Ana Sayfa
              </Link>
            </li>
            
            
            <li className='nav-item'>
              <Link
                to='/products'
                className='nav-links'
                onClick={closeMobileMenu}>
                Hakkımızda
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}>
                Hizmetlerimiz
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/bilgilendirme'
                className='nav-links'
                onClick={closeMobileMenu}>
                İletişim
              </Link>
            </li>

            {/* <li>
              <Link
                to='/sign-up'
                className='nav-links-mobile'
                onClick={closeMobileMenu}
              >
                Kayıt Ol
              </Link>
            </li> */}
          </ul>
          {/* {button && <Button buttonStyle='btn--outline'>Kayıt Ol</Button>} */}
        </div>
      </nav>
    </>
  );
}

export default Navbar;